var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "mt-4", attrs: { cols: "12" } },
        [
          _c("div", [
            _c("p", { staticClass: "text-h5 mb-0 pb-0 black--text" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("account.subscription.planTitle")) + " "
              )
            ])
          ]),
          _c(
            "v-row",
            { attrs: { justify: "space-between", align: "center" } },
            [
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c("p", { staticClass: "mb-1" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("account.subscription.currentPlan"))
                  ),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.userAccessTier) +
                        " " +
                        _vm._s(
                          _vm.getSubscriptionPeriodString(_vm.tierInterval)
                        ) +
                        " "
                    )
                  ]),
                  _vm.upcomingInvoiceDate
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("account.subscription.renewal")) +
                            " "
                        ),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(_vm._s(_vm.upcomingInvoiceDate))
                        ]),
                        _vm._v(". ")
                      ])
                    : _vm.subscriptionExpiredAt
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("account.subscription.expiring")) +
                            " "
                        ),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(_vm._s(_vm.subscriptionExpiredAt))
                        ]),
                        _vm._v(". ")
                      ])
                    : _vm._e()
                ]),
                _vm.showTeamSeats
                  ? _c("p", { staticClass: "mb-1" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("account.subscription.seats.regularText")
                          ) +
                          " "
                      ),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("account.subscription.seats.boldText", {
                              0: _vm.seatInfo.subscriptionSeats,
                              1: _vm.seatInfo.usedSeats
                            })
                          ) + " "
                        )
                      ])
                    ])
                  : _vm._e()
              ]),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        bottom: "",
                        color: "transparent",
                        disabled: _vm.currentUserAdmin
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "div",
                                _vm._g(_vm._b({}, "div", attrs, false), on),
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      class: !_vm.currentUserAdmin
                                        ? "black--text"
                                        : "white--text",
                                      attrs: {
                                        disabled: !_vm.currentUserAdmin,
                                        outlined: "",
                                        depressed: "",
                                        color: "primary"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          _vm.dialog = true
                                          _vm.errorMessage = ""
                                          _vm.paymentMessage = ""
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("buttons.changePlan"))
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("vitrue-tooltip", {
                        attrs: { message: _vm.$t("tooltips.adminOnly") }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-row", { staticClass: "px-3" })
        ],
        1
      ),
      _vm.errorMessage
        ? _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c("alert-message", {
                attrs: { message: _vm.errorMessage, type: "error" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.paymentMessage
        ? _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c("alert-message", {
                attrs: { message: _vm.paymentMessage, type: "success" }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-card-title",
                { staticClass: "px-0" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("account.subscription.invoiceTitle")) +
                      " "
                  ),
                  _c("v-spacer"),
                  _c("v-text-field", {
                    attrs: {
                      "append-icon": "mdi-magnify",
                      label: _vm.$t("textFields.search"),
                      "single-line": "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ],
                1
              ),
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.invoiceHistory,
                  "items-per-page": 5,
                  search: _vm.search,
                  "sort-by": ["formattedCreatedAt"],
                  "sort-desc": [true],
                  loading: !_vm.historyDataIsLoaded,
                  "loading-text": _vm.$t("account.subscription.loading"),
                  "no-data-text": _vm.$t("account.subscription.table.noData")
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.receipt",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        !item.downloading
                          ? _c(
                              "v-icon",
                              {
                                attrs: { right: "", color: "blue" },
                                on: {
                                  click: function($event) {
                                    return _vm.downloadReceipt(item)
                                  }
                                }
                              },
                              [_vm._v("arrow_circle_down")]
                            )
                          : _c("v-progress-circular", {
                              attrs: { indeterminate: "", color: "grey" }
                            })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.dialog
        ? _c("SubscriptionDialog", {
            on: {
              success: function($event) {
                return _vm.updateOnPaymentSuccess($event)
              }
            },
            model: {
              value: _vm.dialog,
              callback: function($$v) {
                _vm.dialog = $$v
              },
              expression: "dialog"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }