/* eslint-disable no-debugger */
<template>
  <v-row>
    <v-col cols="12" class="mt-4">
      <div>
        <p class="text-h5 mb-0 pb-0 black--text">
          {{ $t("account.subscription.planTitle") }}
        </p>
      </div>
      <v-row justify="space-between" align="center">
        <v-col cols="auto">
          <p class="mb-1">
            {{ $t("account.subscription.currentPlan")
            }}<span class="font-weight-bold">
              {{ userAccessTier }}
              {{ getSubscriptionPeriodString(tierInterval) }}
            </span>
            <span v-if="upcomingInvoiceDate">
              {{ $t("account.subscription.renewal") }}
              <span class="font-weight-bold">{{ upcomingInvoiceDate }}</span
              >.
            </span>
            <span v-else-if="subscriptionExpiredAt">
              {{ $t("account.subscription.expiring") }}
              <span class="font-weight-bold">{{ subscriptionExpiredAt }}</span
              >.
            </span>
          </p>
          <p class="mb-1" v-if="showTeamSeats">
            {{ $t("account.subscription.seats.regularText") }}
            <span class="font-weight-bold"
              >{{
                $t("account.subscription.seats.boldText", {
                  0: seatInfo.subscriptionSeats,
                  1: seatInfo.usedSeats
                })
              }}
            </span>
          </p>
        </v-col>
        <v-col cols="12" class="py-0"
          ><v-tooltip bottom color="transparent" :disabled="currentUserAdmin">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  :disabled="!currentUserAdmin"
                  outlined
                  depressed
                  color="primary"
                  :class="!currentUserAdmin ? 'black--text' : 'white--text'"
                  @click.stop="
                    dialog = true;
                    errorMessage = '';
                    paymentMessage = '';
                  "
                  >{{ $t("buttons.changePlan") }}</v-btn
                >
              </div>
            </template>
            <vitrue-tooltip :message="$t('tooltips.adminOnly')" /> </v-tooltip
        ></v-col>
      </v-row>
      <v-row class="px-3"> </v-row>
    </v-col>
    <v-col cols="10" v-if="errorMessage">
      <alert-message :message="errorMessage" type="error"></alert-message>
    </v-col>
    <v-col cols="10" v-if="paymentMessage">
      <alert-message :message="paymentMessage" type="success"></alert-message>
    </v-col>
    <v-col cols="12">
      <v-card flat>
        <v-card-title class="px-0">
          {{ $t("account.subscription.invoiceTitle") }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('textFields.search')"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="invoiceHistory"
          :items-per-page="5"
          :search="search"
          :sort-by="['formattedCreatedAt']"
          :sort-desc="[true]"
          :loading="!historyDataIsLoaded"
          :loading-text="$t('account.subscription.loading')"
          :no-data-text="$t('account.subscription.table.noData')"
        >
          <template v-slot:item.receipt="{ item }">
            <v-icon
              right
              color="blue"
              v-if="!item.downloading"
              @click="downloadReceipt(item)"
              >arrow_circle_down</v-icon
            >
            <v-progress-circular
              v-else
              indeterminate
              color="grey"
            ></v-progress-circular>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <SubscriptionDialog
      v-if="dialog"
      v-model="dialog"
      @success="updateOnPaymentSuccess($event)"
    />
  </v-row>
</template>

<script>
const SubscriptionDialog = () => import("./SubscriptionDialog");
import stringInject from "stringinject";
import { mapGetters, mapActions } from "vuex";
import { mapMutations } from "vuex";
import AlertMessage from "./AlertMessage";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import TeamService from "@/services/teamService.js";
import {
  getUserAccessTier,
  getSubscriptionInvoice,
  getSubscriptionsHistory,
  getCustomerSeatInfo
} from "../../customApi";
let link;
export default {
  components: {
    AlertMessage,
    SubscriptionDialog,
    VitrueTooltip
  },
  props: {
    showDialogOnLoad: Boolean
  },
  data() {
    return {
      headers: [
        {
          text: "",
          align: "right",
          width: "1%",
          sortable: false,
          value: "icon"
        },
        {
          text: this.$t("account.subscription.table.date"),
          value: "formattedCreatedAt"
        },
        {
          text: this.$t("account.subscription.table.plan"),
          value: "subscriptionName"
        },
        {
          text: this.$t("account.subscription.table.receipt"),
          value: "receipt"
        }
      ],
      historyDataIsLoaded: false,
      search: "",
      invoiceHistory: [],
      dialog: false,
      errorMessage: "",
      subscriptionDetails: "",
      subscriptionPlans: [],
      subscriptionPriceId: "",
      paymentMessage: "",
      subscriptionsPriority: {
        Lite: 1,
        Essential: 2,
        Pro: 3,
        Enterprise: 4
      },
      upcomingInvoiceDate: null,
      subscriptionExpiredAt: null,
      changeOnThisPage: true,
      seatInfo: {
        subscriptionSeats: "...",
        usedSeats: "..."
      }
    };
  },
  async mounted() {
    this.dialog = this.showDialogOnLoad && this.isSuperAdmin;
    await this.updateUserSubscriptionAndInvoiceHistory();
  },
  methods: {
    ...mapMutations([
      "setUserAccessTier",
      "setUserAccessTierInterval",
      "setTeamMemberId"
    ]),
    GetInjectedPricePerAssessmentString(
      value,
      currencySign,
      price,
      numberOfAssessments
    ) {
      var pricePerAssessment = (price / numberOfAssessments).toFixed(2);
      var result = stringInject(value, [currencySign, pricePerAssessment]);
      return result;
    },
    async updateUserSubscriptionAndInvoiceHistory() {
      if (!this.teamMemberId) {
        let memberInfo = await TeamService.getTeamMemberInfo();
        this.setTeamMemberId(memberInfo.teamMemberId);
      }
      this.getInvoiceHistory();
      this.getUserAccessTier();
      if (this.showTeamSeats) {
        this.getCustomerSeatInformation();
      }
    },
    async getCustomerSeatInformation() {
      try {
        let seatInfo = await getCustomerSeatInfo(this.teamMemberId);
        this.seatInfo = {
          subscriptionSeats: seatInfo.subscriptionSeats ?? seatInfo.totalSeats,
          usedSeats: seatInfo.usedSeats ?? seatInfo.uniqueUserCount
        };
      } catch (err) {
        this.setErrorMessage(
          this.$t("account.subscription.seats.failedToRetrieve")
        );
        this.seatInfo.subscriptionSeats = "-";
        this.seatInfo.usedSeats = "-";
      }
    },
    async getInvoiceHistory() {
      try {
        let subscriptions = await getSubscriptionsHistory(this.teamMemberId);
        this.invoiceHistory = subscriptions.map(a => ({
          ...a,
          downloading: false,
          formattedCreatedAt: this.getFormattedDate(a.createdAt)
        }));
      } catch (err) {
        this.setErrorMessage(err);
      } finally {
        this.historyDataIsLoaded = true;
      }
    },
    getFormattedDate(date) {
      let options = {
        day: "numeric",
        month: "long",
        year: "numeric"
      };
      //"en-US" August 14, 2020.
      //"en-GB" 14 August 2020.
      let formattedDate = new Date(date).toLocaleDateString("en-US", options);
      return formattedDate;
    },
    generateDownloader(linkUrl) {
      link = document.createElement("a");
      link.href = linkUrl;
      link.click();
      link.remove();
    },
    setErrorMessage(error) {
      if (!error.message) {
        this.errorMessage = this.$t("genericError");
        return;
      }
      this.errorMessage = error.message.includes("status code")
        ? this.$t("genericError")
        : error.message;
    },
    downloadReceipt(item) {
      item.downloading = true;
      getSubscriptionInvoice(this.teamMemberId, item.invoiceId)
        .then(response => {
          this.generateDownloader(response);
        })
        .then(() => (item.downloading = false))
        // eslint-disable-next-line no-console
        .catch(err => {
          item.downloading = false;

          this.setErrorMessage(err);
        });
    },
    getUserAccessTier() {
      return getUserAccessTier(this.teamMemberId)
        .then(response => {
          this.setUserAccessTier(response.accessTier);
          this.setUserAccessTierInterval(response.interval);

          this.upcomingInvoiceDate = response.nextPaymentDate
            ? this.getFormattedDate(response.nextPaymentDate)
            : response.nextPaymentDate;
          this.subscriptionExpiredAt = response.currentPeriodEndDate
            ? this.getFormattedDate(response.currentPeriodEndDate)
            : response.currentPeriodEndDate;
        })
        .catch(err => {
          this.setErrorMessage(err);
        });
    },
    getSubscriptionPeriodString(interval) {
      return interval
        ? this.$t("account.subscription.intervals." + interval)
        : "...";
    },
    async updateOnPaymentSuccess(orderType) {
      let type =
        orderType === "upgrade"
          ? this.$t("account.subscription.upgraded")
          : this.$t("account.subscription.created");
      this.paymentMessage = this.$t("account.subscription.successMessage", {
        0: type
      });
      await this.updateUserSubscriptionAndInvoiceHistory();
    }
  },
  computed: {
    ...mapGetters([
      "teamMemberId",
      "userAccessTier",
      "tierInterval",
      "currentUserTeamAndSubscriptionBasicInformation",
      "isSuperAdmin",
      "showTeamSeats"
    ]),
    dialogSize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100%";
      }
      return "80%";
    },
    currentUserAdmin() {
      return this.currentUserTeamAndSubscriptionBasicInformation?.role === 10;
    }
  }
};
</script>
